import { fetch } from 'utils/fetch';
import keysToCamel from 'utils/keysToCamel';
import { urlEncodeBody } from 'utils/requests';

export async function resetPassword(email: string): Promise<any> {
  const response = await fetch(`/auth/forgot_password`, {
    method: 'POST',
    body: urlEncodeBody({ email })
  });

  const data = await response.json();

  return keysToCamel(data);
}

export type ResetPasswordConfirmPayload = {
  password: string;
  key: string;
};

export async function resetPasswordConfirm(payload: ResetPasswordConfirmPayload): Promise<any> {
  await fetch(`/auth/reset_password`, {
    method: 'POST',
    body: urlEncodeBody(payload)
  });
}
