import React from 'react';
import AuthLayout from 'components/AuthLayout';
import ResetPasswordPage from 'views/ForgotPasswordPage';

const ForgotPassword = () => {
  return (
    <AuthLayout>
      <ResetPasswordPage />
    </AuthLayout>
  );
};

export default ForgotPassword;
